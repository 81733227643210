<template>
	<v-container fluid>
		<v-row v-if="!isLoading" class="mb-12">
			<v-col cols="12" class="text-center">
				<span class="text-left text-capitalize font-weight-bold text-h5 mb-2"
					>{{ $t('greeting') }} {{ shortName }},</span
				>
				<v-btn class="ml-3 mb-2" color="primary" to="/add-pet" small
					><v-icon small> mdi-plus </v-icon>{{ $t('createNewPet') }}</v-btn
				>
			</v-col>
		</v-row>

		<v-row justify="center">
			<v-col cols="12" sm="6" md="4" lg="3" v-for="pet in pets" :key="pet.id">
				<v-card
					@click="selectPet(pet)"
					class="mx-auto mb-2"
					max-width="350"
					hover
				>
					<v-img
						height="320"
						:src="pet.image ? pet.image : defaultAvatar"
					></v-img>

					<v-card-title>{{ pet.name }}</v-card-title>

					<v-card-text>
						<!-- Vaccine Alert -->
						<div v-for="(vaccine, i) in pet.vaccines" :key="i">
							<v-alert
								class="mb-1"
								v-model="vaccine.expired"
								type="error"
								dense
							>
								{{ $t('expiringVaccine') }}
							</v-alert>
						</div>

						<!-- Deworming Alert -->
						<div v-for="deworming in pet.dewormings" :key="deworming.endDate">
							<v-alert
								class="mb-0"
								v-model="deworming.expired"
								type="error"
								dense
							>
								{{ $t('expiringDeworming') }}
							</v-alert>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<!-- Loader -->
		<div v-if="isLoading" class="text-center">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
		</div>
	</v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
	data: () => ({
		isLoading: false,
		pets: [],
		defaultAvatar: require('@/assets/default-avatar.jpg'),
		isVaccineExpiring: true,
		isDewormingExpiring: true,
	}),
	computed: {
		...mapState('auth', ['user']),
		shortName() {
			return this.user && this.user.email
				? this.user.email.substring(0, this.user.email.lastIndexOf('@'))
				: '';
		},
	},
	methods: {
		...mapActions('pets', ['getPets']),
		formatDate: date => (date = moment(date).format('DD.MM.YYYY')),
		selectPet(pet) {
			this.$router.push({
				name: 'Pet',
				params: { slug: pet.slug, id: pet._id },
			});
		},
		checkDewormings(pet) {
			if (pet.dewormings.length > 0) {
				pet.dewormings.forEach(deworming => {
					const today = new Date();
					const endDate = new Date(deworming.dateEnd);

					if (deworming.isCurrent) {
						const differenceInTime = endDate.getTime() - today.getTime();
						const differenceInDays = differenceInTime / (1000 * 3600 * 24);

						if (differenceInDays <= 5) {
							deworming.expired = true;
						} else {
							deworming.expired = false;
						}
					} else {
						deworming.expired = false;
					}
				});
			}
		},
		checkVaccines(pet) {
			if (pet.vaccines.length > 0) {
				pet.vaccines.forEach(vaccine => {
					const today = new Date();
					const endDate = new Date(vaccine.dateEnd);

					// if (moment(endDate).isSame(today, 'month')) {
					if (vaccine.isCurrent) {
						const differenceInTime = endDate.getTime() - today.getTime();
						const differenceInDays = differenceInTime / (1000 * 3600 * 24);

						if (differenceInDays <= 5) {
							vaccine.expired = true;
						} else {
							vaccine.expired = false;
						}
					} else {
						vaccine.expired = false;
					}
				});
			}
		},
	},
	async mounted() {
		this.isLoading = true;

		try {
			const res = await this.getPets(this.user._id);

			this.pets = res.data.data;

			this.pets.forEach(pet => {
				this.checkDewormings(pet);
				this.checkVaccines(pet);
			});
		} catch (error) {
			console.error(error);
		} finally {
			this.isLoading = false;
		}
	},
};
</script>

<style lang="scss" scoped></style>
